import React from "react";
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from "react-router-dom"

import { ReactComponent as TelegramIcon } from '../../assets/svg/social/telegram.svg'
import { ReactComponent as InstagramIcon } from '../../assets/svg/social/instagram.svg'
import { ReactComponent as TwitterIcon } from '../../assets/svg/social/twitter.svg'
import { ReactComponent as DiscordIcon } from '../../assets/svg/social/discord.svg'
import { ReactComponent as LinkedInIcon } from '../../assets/svg/social/linkedin.svg'
import { ReactComponent as MediumIcon } from '../../assets/svg/social/medium.svg'

import { ReactComponent as LogoIcon } from '../../assets/svg/logo.svg'

import "./styles.css"

const footer = [
  {
    title: 'Home',
    url: '',
  },
  {
    title: 'Lightpaper',
    url: 'https://drive.google.com/file/d/1YOzsZPH5nCd67nqLxuBBFU9AG5kmOvxY/view?usp=sharing',
    disabled: false, 
    target: true,
  },
  {
    title: 'Community',
    url: 'https://discord.com/invite/duAySrR4c4',
    target: true,
  },
  {
    title: 'Blog',
    url: '',
    disabled: true, 
  },
  {
    title: 'Contact',
    local: true,
    url: '/contact',
  }
]

const Footer = ({ className }) => {
  return (
    <div className={cn('footer', className)}>
      <LogoIcon className="footer__logo" />
      <div className="footer__menu">
        { footer.map((option) => {
          if (option.local) {
            return (
              <Link className="footer__item" to={option.url}>
                <h4 className="footer__item-title">{option.title}</h4>
              </Link>
            )
          }
          return (
            <a target={option.target && "_blank"} href={option.url || '#'} key={option.title} className={cn("footer__item", option.disabled && "footer__item-disabled")}>
              <h4 className="footer__item-title">{option.title}</h4>
            </a>
          )
        }
        )}
        <div className="footer__social coming-social">
          <a target="_blank" className="coming-social-item" href="https://twitter.com/AltarDao" rel="noreferrer"><TwitterIcon /></a>
          <a target="_blank" className="coming-social-item" href="https://discord.com/invite/duAySrR4c4" rel="noreferrer"><DiscordIcon /></a>
          <a target="_blank" className="coming-social-item" href="https://t.me/altardao" rel="noreferrer"><TelegramIcon /></a>
          <a target="_blank" className="coming-social-item" href="https://www.linkedin.com/company/altar-metaverse" rel="noreferrer"><LinkedInIcon /></a>
          <a target="_blank" className="coming-social-item" href="https://www.instagram.com/altarnft" rel="noreferrer"><InstagramIcon /></a>
          <a className="coming-social-item" href="/"><MediumIcon /></a>
        </div>
        <p className="footer__menu-copyright">
          Copyright © Altar 2022
        </p>
      </div>
    </div>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: '',
}

export default Footer;
