import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom"
import cn from 'classnames'
import AliceCarousel from 'react-alice-carousel';

import Footer from "../../components/footer";
import { AdvancedHeader } from "../../components/header";

import mail from '../../assets/svg/coming/mail.svg'
import appStore from '../../assets/svg/platforms/appstore.svg'
import playMarket from '../../assets/svg/platforms/playmarket.svg'

import Alien1 from '../../assets/img/aliens/alien1.png'
import Alien2 from '../../assets/img/aliens/alien2.png'
import Alien3 from '../../assets/img/aliens/alien3.png'
import Alien4 from '../../assets/img/aliens/alien4.png'
import Alien5 from '../../assets/img/aliens/alien5.png'
import Alien6 from '../../assets/img/aliens/alien6.png'

import Timeline1 from '../../assets/img/timeline/timeline1.png'
import Timeline2 from '../../assets/img/timeline/timeline2.png'
import Timeline3 from '../../assets/img/timeline/timeline3.png'
import Timeline4 from '../../assets/img/timeline/timeline4.png'

import StartBox from '../../assets/img/startBox/startBox.png'
import StartBoxOne from '../../assets/img/startBox/startBox1.png'
import StartBoxTwo from '../../assets/img/startBox/startBox2.png'

/*
import Blog1 from '../../assets/img/blog/blog1.png'
import Blog2 from '../../assets/img/blog/blog2.png'
import Blog3 from '../../assets/img/blog/blog3.png'
*/

import Ecosystem1 from '../../assets/img/ecosystem/wallet.png'
import Ecosystem2 from '../../assets/img/ecosystem/nft.png'
import Ecosystem3 from '../../assets/img/ecosystem/fund.png'
import Ecosystem4 from '../../assets/img/ecosystem/launchpad.png'
import Ecosystem5 from '../../assets/img/ecosystem/metaverse.png'
import Ecosystem6 from '../../assets/img/ecosystem/exchange.png'
import Ecosystem7 from '../../assets/img/ecosystem/token.png'
import Ecosystem8 from '../../assets/img/ecosystem/gamefi.png'
import Ecosystem9 from '../../assets/img/ecosystem/shopping.png'
import Ecosystem10 from '../../assets/img/ecosystem/defi.png'
import Ecosystem11 from '../../assets/img/ecosystem/security.png'
import Ecosystem12 from '../../assets/img/ecosystem/application.png'

import Ecosystem from '../../assets/img/ecosystem.png'
import AltarEcosystem from '../../assets/img/ecosystem/altar.png'

import { ReactComponent as Binance} from '../../assets/img/support/binance.svg'
import { ReactComponent as Ethereum} from '../../assets/img/support/ethereum.svg'
import { ReactComponent as Solana} from '../../assets/img/support/solana.svg'
import { ReactComponent as Unity} from '../../assets/img/support/unity.svg'
import { ReactComponent as Unreal} from '../../assets/img/support/unreal.svg'
import { ReactComponent as Polygon} from '../../assets/img/support/polygon.svg'

import { ReactComponent as TelegramIcon } from '../../assets/svg/social/telegram.svg'
import { ReactComponent as InstagramIcon } from '../../assets/svg/social/instagram.svg'
import { ReactComponent as TwitterIcon } from '../../assets/svg/social/twitter.svg'
import { ReactComponent as DiscordIcon } from '../../assets/svg/social/discord.svg'
import { ReactComponent as LinkedInIcon } from '../../assets/svg/social/linkedin.svg'
import { ReactComponent as MediumIcon } from '../../assets/svg/social/medium.svg'

import { ReactComponent as LeftArrow } from '../../assets/svg/actions/left-arrow.svg'
import { ReactComponent as RightArrow } from '../../assets/svg/actions/right-arrow.svg'

import './styles.css';
import "react-alice-carousel/lib/alice-carousel.css";

const supportList = [
  Binance,
  Ethereum,
  Unity,
  Solana,
  Unreal,
  Polygon,
]

const slideImages = [
  {
    url: Alien4,
    name: 'Mixed Martian Arts Club'
  },
  {
    url: Alien5,
    name: 'Mixed Martian Arts Club'
  },
  {
    url: Alien6,
    name: 'Mixed Martian Arts Club'
  },
  {
    url: Alien1,
    name: 'Gay Aliens Society'
  },
  {
    url: Alien2,
    name: 'Gay Aliens Society'
  },
  {
    url: Alien3,
    name: 'Gay Aliens Society'
  },
];

const startSteps = [
  {
    url: StartBox,
    name: 'Connect your wallet',
    description: 'Easy connect with us through  your favorite wallet'
  },
  {
    url: StartBoxOne,
    name: 'Mint/Buy an NFT',
    description: 'Mint or Buy our NFTs and add them you your wallet'
  },
  {
    url: StartBoxTwo,
    name: 'Interact in AR 3D',
    description: 'Download our App and play with AR/3D'
  },
];

const timeLine = [
  {
    img: Timeline1,
    head: 'Q1 2022',
    options: [
      'Location Based NFT’s',
      'Web3 Integration',
      'NFT Drops',
      'NFT + AR Generator',
    ],
  },
  {
    img: Timeline2,
    head: 'Q2 2022',
    options: [
      'XR+ NFT Wallet',
      'Personal Collections',
      'NFT Streaming',
      'Multi-Chain NFT Bridge',
    ],
  },
  {
    img: Timeline3,
    head: 'Q3 2022',
    options: [
      'Multichain Collection Sharing',
      'AR/VR/XR + Gaming',
      'Marketplace Aggregation',
      'Smart & Secure  Data Storage',
    ],
  },
  {
    img: Timeline4,
    head: 'Q4 2022',
    options: [
      'Ledger Integration',
      'NFT Breeding',
      'Physical Goods Marketplace',
      'AI-Generated 3D/AR Art',
    ],
  }
]

const ecosystemList = [
  {
    label: 'Wallet',
    img: Ecosystem1,
  },
  {
    label: 'NFT',
    img: Ecosystem2,
  },
  {
    label: 'Fund',
    img: Ecosystem3,
  },
  {
    label: 'Launchpad',
    img: Ecosystem4,
  },
  {
    label: 'Metaverse',
    img: Ecosystem5,
  },
  {
    label: 'Exchange',
    img: Ecosystem6,
  },
  {
    label: 'Token',
    img: Ecosystem7,
  },
  {
    label: 'Gamefi',
    img: Ecosystem8,
  },
  {
    label: 'Shopping',
    img: Ecosystem9,
  },
  {
    label: 'Defi',
    img: Ecosystem10,
  },
  {
    label: 'Security',
    img: Ecosystem11,
  },
  {
    label: 'Application',
    img: Ecosystem12,
  }
]

/*
const blogs = [
  {
    id: 'Blog 1',
    img: Blog1,
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu nibh non et tempus. Aliquam lobortis mo...'
  },
  {
    id: 'Blog 2',
    img: Blog2,
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu nibh non et tempus. Aliquam lobortis mo...'
  },
  {
    id: 'Blog 3',
    img: Blog3,
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu nibh non et tempus. Aliquam lobortis mo...'
  }
]

*/

const ComingSoon = () => {
  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0)
  const [itemsInSlide, setItemsInSlide] = useState(1)
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const responsive = {
    0: {
      items: 1
    },
    600: {
      items: 2
    },
    800: {
      items: 3
    },
    1024: {
      items: 4
    }
  };

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  const goNext = () => { 
    const length = slideImages.length
    let newIndex = currentIndex + itemsInSlide
    if (newIndex + itemsInSlide  > length) newIndex = length - itemsInSlide

    sliderRef.current.slideTo(newIndex)
    setTimeout(() => {
      setCurrentIndex(newIndex)
    }, 1000)
  }

  const goBack = () => {
    const newIndex = currentIndex - itemsInSlide
    if (newIndex > -1) {
      sliderRef.current.slideTo(newIndex)
      setTimeout(() => {
        setCurrentIndex(newIndex)
      }, 1000)
    } else {
      sliderRef.current.slideTo(0)
      setTimeout(() => {
        setCurrentIndex(0)
      }, 1000)
    }
  }

  const handleOnSlideChange = (event) => {
    const { itemsInSlide, item } = event
    setItemsInSlide(itemsInSlide)
    setCurrentIndex(item)
  }

  const getAliens = slideImages.map(({url, name}, index) => (
    <div
      className="coming-example__slide-item"
      key={index}
    >
      <img
        className="coming-example__slide"
        src={url}
        alt={name + index}
      />
        <p>{name}</p>
      </div>
  ))

  return <div className="coming-wrapper">
    <div className="coming-background"></div>
    <div className="coming">
      <div className="coming-container">
        <AdvancedHeader mobileView={windowSize.width <= 575} />
        <div className="coming-content">
          <div>
            <h1 className="coming-title">
              Cross <span className="point"/> Meta <span className="point" />  Hub
            </h1>
            <h2  className="coming-subtitle">MARKETPLACE OF THINGS</h2>
            <p className="coming-about">
              The most secure and entertaining Cross Metaverse <strong>Wallet.<br /> Collect,
              Share and Sell</strong>  your favorite items through <strong>NFT + AR</strong>.
            </p>
            <h1 className="coming-soon">
              Metaverse is coming…
            </h1>
            <Link to="/whitelist" className="coming-whitelist">
              <span className="coming-mail">
                <img src={mail} alt="mail" />
              </span>
              <p>get whitelisted</p>
            </Link>
            <h3 className="demo-title">Try demo now</h3>
            <div className="demo-list">
              <a className="demo-item" href="https://apps.apple.com/ua/app/altar-d/id1604389865?l=ru" target="_blank" rel="noreferrer">
                <span className="appstore">
                  <img className="" src={appStore} alt="appstore" />
                </span>
                <p className="demo-label">App Store</p>
              </a>
              <a className="demo-item" href="https://play.google.com/store/apps/details?id=pro.cyborgs.topgame.altardemo" target="_blank" rel="noreferrer">  
                <span className="playmarket">
                  <img className="" src={playMarket} alt="appstore" />
                </span>
                <p className="demo-label">Play Market</p>
              </a>
            </div>
          </div>
        </div>
         <div className="coming-social coming-social__list">
          <a className="coming-social-item" target="_blank" href="https://discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4//twitter.com/AltarDao" rel="noreferrer"><TwitterIcon /></a>
          <a className="coming-social-item" target="_blank" href="https://discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4" rel="noreferrer"><DiscordIcon /></a>
          <a className="coming-social-item" target="_blank" href="https://discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4//t.me/altardao" rel="noreferrer"><TelegramIcon /></a>
          <a className="coming-social-item" target="_blank" href="https://discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4//www.linkedin.com/company/altar-metaverse" rel="noreferrer"><LinkedInIcon /></a>
          <a className="coming-social-item" target="_blank" href="https://discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4//www.instagram.com/altarnft" rel="noreferrer"><InstagramIcon /></a>
          <a className="coming-social-item" target="_blank" href="/"><MediumIcon /></a>
        </div>
      </div>
      <div className="coming-example">
        <div className="coming-example__header">
          <h2 className="coming-example__title">We’re currently working on:</h2>
          <div className="coming-example__slide-actions">
            <button
              disabled={currentIndex === 0}
              className={cn(currentIndex === 0 && "coming-example__slide-disable")}
              onClick={goBack}
            >
              <LeftArrow />
            </button>
            <button
              disabled={currentIndex === getAliens.length - itemsInSlide}
              className={cn(currentIndex === getAliens.length - itemsInSlide && "coming-example__slide-disable")}
              onClick={goNext}
            >
              <RightArrow />
            </button>
          </div>
        </div>
        <div className="coming-example__slides">
          <AliceCarousel
            activeIndex={currentIndex}
            slideToIndex={currentIndex}
            currentIndex={currentIndex}
            responsive={responsive}
            ref={sliderRef}
            items={getAliens}
            autoPlay={false}
            disableButtonsControls={true}
            onInitialized={handleOnSlideChange}
            onSlideChanged={handleOnSlideChange}
            onResized={handleOnSlideChange}
            autoPlayInterval="3000"
          />
        </div>
      </div>
      <div className="coming-support">
        <h3 className="coming-support__title">we’re WORKING WITH</h3>
        <ul className="coming-support__list">
          { supportList.map((Component, index) => (
            <li className="coming-support__item" key={index}>
              <Component />
            </li>
          ))}
        </ul>
      </div>
      <div className="coming-start">
        <h2 className="coming-start__title">How to get started</h2>
        <ul className="coming-start__list">
          { startSteps.map((item, index) => (
            <li key={index} className="coming-start__item">
              <img src={item.url} alt={`Step ${index}`} />
              <h3>{item.name}</h3>
              <p>{item.description}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="coming-life">
        <div className="coming-life__background"></div>
        <div className="coming-life__content">
          <h2 className="coming-life__content-title">Altar Ethos</h2>
          <p className="coming-life__content-description">
            Since the dawn of history, humans have braved adventure
            to satisfy their need for exploration, acquisition,
            appreciation, & awe. ALTAR meets all four of these
            needs. As we brave the metaverse searching for
            experience, acquiring AR/NFT artifacts, memorializing
            our prowess. Exploring augmented realities, we
            discover artifacts, demonstrate skill and generosity,
            acquire tokens of adventures, and memorialize these
            through ownership, appreciation, & awe.
          </p>
          <h4 className="coming-life__try">Try demo now </h4>
          <div className="demo-list">
            <a className="demo-item" target="_blank" href="https://apps.apple.com/ua/app/altar-d/id1604389865?l=ru" rel="noreferrer">
              <span className="appstore">
                <img className="" src={appStore} alt="appstore" />
              </span>
              <p className="demo-label">App Store</p>
            </a>
            <a className="demo-item" target="_blank" href="https://play.google.com/store/apps/details?id=pro.cyborgs.topgame.altardemo" rel="noreferrer">  
              <span className="playmarket">
                <img className="" src={playMarket} alt="appstore" />
              </span>
              <p className="demo-label">Play Market</p>
            </a>
          </div>
        </div>
      </div>
      <div className="coming-ecosystem">
        <h2 className="coming-ecosystem__title">Ecosystem</h2>
        <p className="coming-ecosystem__description">
          ALTAR solves the difficulty of integrating <br />
          the metaverse in the reality of things. 
        </p>
        { windowSize.width > 575 ? (
          <img className="coming-ecosystem__chart" src={Ecosystem} alt="Ecosystem" />
        ) : (
          <div className="coming-ecosystem__list-container">
            <ul className="coming-ecosystem__list" >
              { ecosystemList.map((item, index) => <li className="coming-ecosystem__list-item" key={index}>
                <img src={item.img} alt={item.name} />
                <p>{item.label}</p>
              </li>)
              }
            </ul>
            <div className="coming-ecosystem__logo-wrapper">
              <img className="coming-ecosystem__logo" src={AltarEcosystem} alt="Ecosystem Altar" />
            </div>
          </div>
        )
        }
      </div>
      <div className="coming-build">
        <div className="coming-build__background"></div>
        <div className="coming-life__content">
          <h2 className="coming-life__content-title">Creativity Drives Awe</h2>
          <p className="coming-life__content-description">
            The Altar is the next-gen Augmented Reality experience where the individually
            created property is connected as 3D objects appreciated beyond their original
            context. Creativity drives awe. Altar’s geolocation provides enhanced property
            rights beyond their original platforms, providing opportunities for practical
            appreciation, cross metaverse, cross experience, cross reality reuse. Whether
            identifying our participation in great adventures, personalizing social
            experiences, staking claims in portals, or simply enhancing quizzes or games, 
          </p>
          <a target="_blank" className="coming-ghost-btn" href="https://discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4//discord.com/invite/duAySrR4c4//drive.google.com/file/d/1YOzsZPH5nCd67nqLxuBBFU9AG5kmOvxY/view?usp=sharing" rel="noreferrer"><span>LIGHTPAPER</span></a>
        </div>
      </div>
      <h2 className="coming-timeline__title">Roadmap</h2>
      <div className="coming-timeline">
        <span className="coming-timeline__line"></span>
        <div className="coming-timeline__content">
          <ul className="coming-timeline__list">
            { timeLine.map((timeline, index) => <li key={index} className="coming-timeline__list-item" >
              <img className="coming-timeline__img" src={timeline.img} alt={timeLine.header} />
                <div className="coming-timeline__item-content" >
                  <h4 className="coming-timeline__header">{timeline.head}</h4>
                  { timeline.options.map((option, index) => <p key={index}>
                      { option }
                    </p>)
                  }
                </div>
              </li>)
            }
          </ul>
        </div>
      </div>
      { /*
        <div className="blog-wrapper">
          <div className="blog__header">
            <h2 className="blog__header-title">Check out our blog</h2>
            <div className="blog__header-actions">
              <button
                onClick={goBack}
              >
                <LeftArrow />
              </button>
              <button
                onClick={goNext}
              >
                <RightArrow />
              </button>
            </div>
          </div>
          <ul className="blog__list">
            { blogs.map((blog, index) => <li key={index} className="blog__item">
                <img className="blog__item-img" src={blog.img} alt={blog.id} />
                <h4 className="blog__item-title">{blog.title}</h4>
                <p className="blog__item-text">{blog.text}</p>
                <button className="blog__item-more">Read more</button>
              </li>) 
            }
          </ul>
          <div className="blog__show-more">
            <button className="blog__show-more-btn">
              Show more all posts
            </button>
          </div>
        </div>
        */}
      <Footer />
    </div>
  </div>
}

export default ComingSoon